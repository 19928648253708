<template>
  <v-menu
    content-class="menu-arrow"
    transition="slide-x-transition"
    :close-on-content-click="false"
    bottom
    right
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        class="button-primary"
        elevation="0"
        width="40px !important"
        height="40px !important"
      >
        <v-icon
          color="#000000"
          size="18px"
        >
          mdi-tune
        </v-icon>
      </v-btn>
    </template>

    <div :class="(tab == 0 || tab == 1 || tab == 2) ? 'content-menu' : 'tab-content-menu-fit' ">
      <div class="display-flex content-wrap">
        <div
          v-if="tab == 0"
          class="width-four-div content-1"
        >
          <p class="text-title mon-bold">{{ texts.orders.filters.textLabelStatus }}</p>
          <div
            class="display-flex align-items-center"
            v-for="(item, index) in texts.orders.filters.orderStatus"
            :key="index"
          >
            <v-checkbox
              v-model="orderStatus"
              :value="item.value"
              :key="index"
            />
            <p class="text-values mon-regular">{{ item.name }}</p>
          </div>
        </div>
        <div
          v-if="tab == 2"
          class="width-four-div content-1"
        >
          <p class="text-title mon-bold">{{ texts.orders.filters.textLabelStatus }}</p>
          <div
            class="display-flex align-items-center"
            v-for="(item, index) in texts.orders.filters.orderStatusFinalizado"
            :key="index"
          >
            <v-checkbox
              v-model="orderStatus"
              :value="item.value"
              :key="index"
            />
            <p class="text-values mon-regular">{{ item.name }}</p>
          </div>
        </div>
        <div
          :style="tab !== 0 ? {width: '100%'} : {}"
          class="width-four-div content-2"
        >
          <p class="text-title mon-bold">{{ texts.orders.filters.textLabelStatusPaid }}</p>
          <div class="display-flex align-items-center">
            <v-checkbox
              v-model="paymentStatusPaid"
              @change="changeStatusPaid"
            />
            <p class="text-values mon-regular">{{ texts.orders.filters.paymentStatusPaid }}</p>
          </div>
          <div class="display-flex align-items-center">
            <v-checkbox
              v-model="paymentStatusToPaid"
              @change="changeStatusToPaid"
            />
            <p class="text-values mon-regular">{{ texts.orders.filters.paymentStatusToPaid }}</p>
          </div>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: "OrderFilterLayout",
  props: {
    texts: {
      type: Object,
    },
    tab: {
      type: Number,
    },
  },
  data() {
    return {
      //VARIABLES
      orderStatus: [],
      paymentStatusPaid: null,
      paymentStatusToPaid: null,
      paymentStatus: null,
    };
  },
  methods: {
    changeStatusPaid: function (e) {
      this.paymentStatusToPaid = null;

      if (this.paymentStatusPaid == true) {
        this.paymentStatus = true;
      } else {
        this.paymentStatus = null;
      }
    },
    changeStatusToPaid: function () {
      this.paymentStatusPaid = null;

      if (this.paymentStatusToPaid == true) {
        this.paymentStatus = false;
      } else {
        this.paymentStatus = null;
      }
    },
  },
  computed: {},
  watch: {
    orderStatus: function () {
      this.$store.commit("setOrderStatus", this.orderStatus);
    },
    paymentStatus: function () {
      this.$store.commit("setPaymentStatus", this.paymentStatus);
    },
    tab: function () {
      this.orderStatus = [];
      (this.paymentStatusPaid = null), (this.paymentStatusToPaid = null);
      this.paymentStatus = null;
    },
  },
};
</script>

<style>
.width-four-div .v-icon.v-icon {
  font-size: 14px;
}

.width-four-div .v-input--selection-controls__ripple {
  height: 20px;
  width: 20px;
  left: -5px;
  top: calc(50% - 18px);
}

.width-four-div .v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0px;
}

.width-four-div .v-input--selection-controls {
  margin-top: 0px;
}

.width-four-div .v-messages {
  display: none;
}

.width-four-div .v-input__slot {
  margin-bottom: 0px;
}

.width-four-div .v-input--selection-controls__input {
  margin-right: 0px !important;
}
</style>

<style scoped>
.text-values {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: -3px;
}

.text-title {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.content-1 {
  width: 70%;
}

.content-2 {
  width: 30%;
}

.tab-content-menu-fit {
  width: fit-content;
  background-color: #ffffff;
  padding: 20px;
}

.content-menu {
  width: 450px;
  background-color: #ffffff;
  padding: 20px;
}

.menu-arrow {
  margin-top: 50px;
  contain: initial;
  overflow: visible;
}

/* .menu-arrow::before {
  position: absolute;
  content: "";
  top: 9px;
  right: 155px;
  transform: translateY(-170%);
  width: 10px;
  height: 13px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 13px solid #fff;
} */

.button-filter {
  width: 40px !important;
  min-width: 40px !important;
  height: 40px !important;
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  opacity: 1;
}

@media (max-width: 960px) {
  .content-menu {
    width: 470px;
  }

  .content-3,
  .content-4 {
    margin-top: 10px;
  }

  .content-1,
  .content-2,
  .content-3,
  .content-4 {
    width: 50%;
  }

  .content-wrap {
    flex-wrap: wrap;
  }

  .remove-flex {
    display: block;
  }

  .remove-ml {
    margin-left: 0px !important;
  }
}

@media (max-width: 600px) {
  .content-menu {
    width: 200px;
  }

  .content-1,
  .content-2,
  .content-3,
  .content-4 {
    width: 100%;
    margin-top: 10px;
  }

  .content-wrap {
    flex-wrap: wrap;
  }
}
</style>